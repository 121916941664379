import {Temporal} from '@js-temporal/polyfill';
import React, { useState } from 'react';
import './App.scss';

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export default function App() {

  let currentTime = Temporal.Now.plainTimeISO();
  let currentDate = Temporal.Now.plainDateISO();

  const [hour, setHour] = useState(currentTime.hour);
  const [minute, setMinute] = useState(currentTime.minute);
  const [second, setSecond] = useState(currentTime.second);

  setInterval(() => {
    currentTime = Temporal.Now.plainTimeISO();

    setHour(currentTime.hour);
    setMinute(currentTime.minute);
    setSecond(currentTime.second);
  }, 1000)
  
  return (
    <div className="App">

      <div className="container">

        <div id="buttons"><div id="red"></div><div id="orange"></div><div id="green"></div></div>

        <h2>{"The current time is:".split(" ").join("\u00A0")}</h2>

        <div className="time">
          {[hour,minute,second].map(x => x.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})).join(":")}
        </div>

        <div className="date">
          {days[currentDate.dayOfWeek - 1]} {months[currentDate.month - 1]} {currentDate.day}, {currentDate.year}
        </div>

      </div>

      
      
    </div>
  );
}
